<template>
 <div>
  <ExpertStatisticsTemplate
   :periodSelector="periodSelector"
   @timeSelector="setPeriod(startDate, endDate)"
   :elements="extensions"
   :clearTimeSelector="clearTimeSelector"
   @selector="
    $emit('update:selector', $event),
     $emit('setSelector', $event),
     $store.dispatch('pbxPeriodSelected', $event)
   "
   @setPeriod="(...args) => setPeriod(...args)"
   :startDate="startDate"
   :endDate="endDate"
   @activeBanner="$emit('activeBanner')"
   @successMsg="$emit('successMsg', $event)"
   @success="$emit('success', $event)"
   :urlType="urlType"
   @setLoading="$emit('setLoading', $event)">
   <template v-slot:selector>
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="
      $emit('action', $event), (selectedElement = $event), setTableData()
     "
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="setPeriod(startDate, endDate)"
     :concatenateData="concatenateData"
     @concatenate="concatenateData = $event"
     :groupDatesQueueReport="groupData" />
   </template>
   <template v-slot:content>
    <PbxHeader
     v-model:search="search"
     pbxMenuType="queueDetail"
     :periodSelector="period"
     :parseDateRange="parseDateRange"
     @select-hostname="selectHostname = $event"
     :selectedQueue="selectedQueue"
     :parseTimeRange="parseTimeRange"
     :timeSelected="selectedtime"
     :buttonSelected="buttonSelected"
     @activeBanner="(activeBanner = true), (key = !key)"
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     urlType="queue" />

    <div class="" v-if="tableData?.length || tableDataConcatenated?.length">
     <div class="overflow-hidden border-gray-200 rounded-md">
      <FullReportTableUsers
       :groupData="groupData"
       :currentSortDir="currentSortDir"
       :tableData="concatenateData ? tableDataConcatenated : filteredData"
       :header="tableHeader"
       :body="tableBody"
       :selectedtime="selectedtime"
       :extensionsArray="extensionsArray"
       v-model:searchExtension="searchExtension"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       @sort="sort($event)" />
     </div>
    </div>

    <div v-else class="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div
      v-if="!this.pbxElements || this.pbxElements.length <= 0"
      class="text-center max-w-lg mx-auto space-y-4">
      <font-awesome-icon
       icon="chart-column"
       class="mx-auto h-10 w-10 text-teal-600"
       aria-hidden="true" />
      <h1 class="text-gray-900 font-bold text-2xl">
       Analysez les appels entrants de vos files d’attente.
      </h1>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       Evaluez le taux de réponse et la durée d'attente pour chaque file
       d'attente. Déterminez quels utilisateurs répondent aux appels et dans
       quels délais.
      </h3>
      <div class="pt-10">
       <PleaseSelectElement
        dataDescription="Veuillez sélectionner une file et une période" />
      </div>
     </div>
     <div
      v-else
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
      <div class="overflow-hidden border-b border-gray-200 rounded-md">
       <NoData />
      </div>
     </div>
    </div>
   </template>
  </ExpertStatisticsTemplate>
 </div>
</template>

<script>
import PleaseSelectElement from "../../components/PleaseSelectElement.vue";
import PopupShareReport from "../../components/PBX/PopupShareReport.vue";
import HoverDatePicker from "../../components/PBX/HoverDatePicker.vue";
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import Tooltip from "../../components/Collect/formTools/Tooltip.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import FullReportTableUsers from "../../components/PBX/FullReportTableUsers.vue";
import {
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/outline";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";

export default {
 props: ["urlType"],
 components: {
  PbxHeader,
  PbxDashboardSelector,
  SuccessBanner,
  FullReportTableUsers,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ChevronDownIcon,
  Tooltip,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  InformationCircleIcon,
  XIcon,
  ExpertStatisticsTemplate,
  HoverDatePicker,
  PopupShareReport,
  PleaseSelectElement,
 },
 data() {
  return {
   currentSortDir: "asc",
   currentSort: "",
   selectedRangeType: "waiting",
   selectedRangeTypeText: "Durées d'attente",
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   queuesOnlyStats: true,
   data: {},
   selectedtime: undefined,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   concatenateData: false,
   startDate: null,
   endDate: null,
   tableDataConcatenated: null,
   tableData: null,
   tableHeader: null,
   tableBody: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];

   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   this.setTableData();
   if (flag) this.concatenateData = true;
  },
  async getData(sentPeriod) {
   let arrayOfElements = [];
   let stringOfElements = "";
   if (
    this.pbxElements &&
    this.pbxElements.length > 0 &&
    this.startDate &&
    this.endDate
   ) {
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);

    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/users-report?start_date=${this.startDate}&end_date=${this.endDate}&dn=${stringOfElements}&start_time=${this.pbxTimeSelected[0]}&end_time=${this.pbxTimeSelected[1]}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async setTableData() {
   this.tableData = [];
   this.tableDataConcatenated = [];
   this.$emit("setLoading", true);
   this.$store.dispatch("pbxElementType", this.urlType);
   const data = await this.getData();
   if (
    this.pbxMap &&
    this.pbxMap["call_queues"] &&
    this.pbxMap["extensions"] &&
    this.selectedElement
   ) {
    this.selectedElement = this.selectedElement?.map(res => {
     return (
      res +
      (this.urlType === "queue"
       ? this.pbxMap["call_queues"][res]
         ? "-" + this.pbxMap["call_queues"][res]["name"]
         : ""
       : this.pbxMap["extensions"][res]
       ? "-" + this.pbxMap["extensions"][res]
       : "")
     );
    });
   }
   this.tableHeader = [
    { text: "Utilisateur", info: "", hasInfo: false, key: "user" },
    {
     text: "Appels Sortants",
     hasInfo: false,
    },
    {
     text: "Appels Entrants",
     hasInfo: false,
    },
    {
     text: "Appels Internes",
     hasInfo: false,
    },
    {
     text: "Total des Appels Répondus",
     hasInfo: false,
    },
   ];
   this.tableBody = ["user", "outbound", "inbound", "internal", "total"];
   if (data?.length) {
    this.tableData = data;
    this.tableDataConcatenated[0] = this.consolidateData(data);
    this.extensionsNameNumberArray = data.map(res => res.user);
   }
   this.$emit("setLoading", false);
  },
  consolidateData(data) {
   const consolidated = {
    user: " - ", // Mark user as '-' for the consolidated row
    outbound_answered: 0,
    outbound_talking_duration_total: 0,
    outbound_talking_duration_avg: 0,
    inbound_answered: 0,
    inbound_talking_duration_total: 0,
    inbound_talking_duration_avg: 0,
    inbound_unanswered: 0,
    inbound_answered_percentage: 0,
    inbound_waiting_duration_avg: 0,
    internal_answered: 0,
    internal_talking_duration_total: 0,
    internal_talking_duration_avg: 0,
    answered_calls: 0,
    duration_total: 0,
    duration_avg: 0,
   };

   const numEntries = data.length;

   data.forEach(row => {
    // Convert string values to integers
    consolidated.outbound_answered += parseInt(row.outbound_answered, 10);
    consolidated.outbound_talking_duration_total += parseInt(
     row.outbound_talking_duration_total,
     10
    );
    consolidated.inbound_answered += parseInt(row.inbound_answered, 10);
    consolidated.inbound_talking_duration_total += parseInt(
     row.inbound_talking_duration_total,
     10
    );
    consolidated.inbound_unanswered += parseInt(row.inbound_unanswered, 10);
    consolidated.internal_answered += parseInt(row.internal_answered, 10);
    consolidated.internal_talking_duration_total += parseInt(
     row.internal_talking_duration_total,
     10
    );
    consolidated.answered_calls += parseInt(row.answered_calls, 10);
    consolidated.duration_total += parseInt(row.duration_total, 10);

    // Summing percentages and averages
    consolidated.inbound_waiting_duration_avg +=
     row.inbound_waiting_duration_avg;
    consolidated.outbound_talking_duration_avg +=
     row.outbound_talking_duration_avg;
    consolidated.inbound_talking_duration_avg +=
     row.inbound_talking_duration_avg;
    consolidated.internal_talking_duration_avg +=
     row.internal_talking_duration_avg;
    consolidated.duration_avg += row.duration_avg;
   });

   // Calculate averages where needed
   consolidated.inbound_answered_percentage = (
    (consolidated.inbound_answered /
     (consolidated.inbound_answered + consolidated.inbound_unanswered)) *
    100
   ).toFixed(0);
   consolidated.inbound_waiting_duration_avg /= numEntries;
   consolidated.outbound_talking_duration_avg /= numEntries;
   consolidated.inbound_talking_duration_avg /= numEntries;
   consolidated.internal_talking_duration_avg /= numEntries;
   consolidated.duration_avg /= numEntries;

   return consolidated;
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedData() {
   const extensionData = this.tableData;
   let extensionDataSorted = extensionData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (parseInt(a[toBeSorted]) < parseInt(b[toBeSorted])) return -1 * modifier;
    if (parseInt(a[toBeSorted]) > parseInt(b[toBeSorted])) return 1 * modifier;
    return 0;
   });
   return extensionDataSorted;
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },
 watch: {
  selectedtime: function (val) {
   let flag = false;
   if (this.concatenateData) {
    flag = true;
    this.concatenateData = false;
   }
   //  this.setTableData();
   //  this.separateQueueExtensionData();
   if (flag) this.concatenateData = true;
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
  },
  concatenateData: function (val) {
   this.setTableData();
  },
 },
 mounted() {
  // if (!this.period && !this.pbxPeriodSelected) {
  //  this.period = this.currentMonthAndYearForPbx();
  // }
  // if (this.pbxPeriodSelected) this.period = this.pbxPeriodSelected;

  // if (this.pbx3cxTableConfig) {
  //  this.timeRange = this.pbx3cxTableConfig?.following_interval;
  //  this.timeGap = this.pbx3cxTableConfig?.initial_interval;
  //  this.numberOfColumns = this.pbx3cxTableConfig?.count_interval;
  // }

  if (this.pbxElements) {
   this.selectedElement = this.pbxElements;

   if (this.pbxElementType !== this.urlType)
    this.$store.dispatch("pbxElements", null);
  }
 },
 computed: {
  filteredData() {
   return this.sortedData().filter(data => {
    if (this.searchExtension?.length > 0) {
     let flag = false;
     this.searchExtension.forEach(user => {
      if (user.includes(data.user)) {
       flag = true;
      }
     });
     return flag;
    }
    return data;
   });
  },
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxElements",
   "pbxMap",
   "pbxElementType",
   "pbx3cxTableConfig",
   "pbxTimeSelected",
  ]),
 },
};
</script>

<style>
.switch-group {
 position: absolute;
 right: 1.3rem;
 top: 23.5rem;
}
.switch-group-no-info {
 position: absolute;
 right: 1.3rem;
 top: 18.5rem;
}
</style>
